<template>
  <div>
    <v-dialog v-model="dialogActa" scrollable max-width="1400px">
      <v-card>
        <v-card-title class="py-3 justify-center text-left">
          EDITAR ACTA
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-5">
            <v-col v-if="actadescripcion.length > 0" cols="12" sm="12" md="12" class="py-0 pb-6">
              <h2>{{ actadescripcion }}</h2>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                dense
                label="Título"
                v-model="titulo"
                required
                :rules="[required]"
                @blur="titulo = titulo.toUpperCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                dense
                label="Subtítulo"
                required
                v-model="subtitulo"
                @blur="subtitulo = subtitulo.toUpperCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="py-1">
              <v-data-table
                dense
                :headers="cParticipantes"
                :items="participantes"
                :items-per-page="-1"
                sort-by="calories"
                class="border"
                hide-default-footer
              >
                <template v-slot:top>
                  <div class="primary w-100 d-flex justify-space-between px-2 white--text body-1" style="height: 40px;">
                    <div class="d-flex align-center py-1">
                      <u>I</u>NVITADOS/PARTICIPANTES
                      <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                    </div>
                    <v-btn color="white" fab icon small @click="addRows" v-shortkey="['ctrl', 'i']" @shortkey="addRows">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:[`item.dni`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.dni">
                    {{ item.dni }}
                    <template v-slot:input>
                      <v-text-field v-model="item.dni" label="Dni" single-line type="number"></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.object" @save="saveName(item.id, item.object, item.new)">
                    {{ item.name }}
                    <template v-slot:input>
                      <div v-if="!item.new" class="d-flex align-baseline">
                        <v-autocomplete
                          v-model="item.object"
                          :search-input.sync="search"
                          :items="itemsParticipantes"
                          item-text="name"
                          item-value="code"
                          label="Nombres"
                          placeholder="Nombres"
                          prepend-icon="mdi-database-search"
                          hide-no-data
                          hide-selected
                          return-object
                        >
                          <template v-slot:selection="data">
                            {{ data.item.name }}
                          </template>
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle>
                                  {{
                                    `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}`
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                        <v-btn icon color="primary" @click="changeNewEditText(item.id)">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                      <v-text-field
                        v-else
                        v-model="item.name"
                        @blur="item.name = item.name.toUpperCase()"
                        label="Nombres"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <v-edit-dialog @save="saveEmail(item.id, item.email)">
                    {{ item.email }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.email"
                        label="Email"
                        :suffix="item.email.indexOf('@') !== -1 ? '' : '@agrovisioncorp.com'"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.leader`]="{ item }">
                  <v-simple-checkbox
                    v-if="item.idsubarea != ''"
                    v-model="item.leader"
                    @input="changeLeader(item.id, item.codigo)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.status" @save="updateParticipation">
                    <v-icon v-if="item.status === 'S'" color="green darken-2"
                      >mdi-checkbox-marked-circle-outline</v-icon
                    >
                    <v-icon v-else-if="item.status === 'I'" color="blue darken-2">mdi-minus-circle-outline</v-icon>
                    <v-icon v-else-if="item.status === 'N'" color="red darken-2">mdi-close-circle-outline</v-icon>
                    <template v-slot:input>
                      <v-select
                        v-model="item.status"
                        :items="itemsStatus"
                        no-data-text="No se encontró información"
                        item-text="text"
                        item-value="value"
                        label="Estado"
                        single-line
                      >
                      </v-select>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click="deleteItemParticipantes(item.id)">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                  <span>No se encontró información</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col cols="12" sm="6" classs="py-0">
              <v-select
                dense
                v-model="escribano"
                :items="itemsEscribano"
                no-data-text="No se encontró información"
                item-text="name"
                item-value="dni"
                label="Escribano"
                return-object
                @click="loadEscribanos"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" class="py-0">
              <v-data-table
                dense
                :headers="cAgenda"
                :items="agenda"
                :items-per-page="-1"
                sort-by="calories"
                class="border"
                hide-default-footer
              >
                <template v-slot:top>
                  <div class="primary w-100 d-flex justify-space-between px-2 white--text body-1" style="height: 40px;">
                    <div class="d-flex align-center py-1">
                      A<u>G</u>ENDA
                      <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                    </div>
                    <v-btn
                      color="white"
                      fab
                      icon
                      small
                      @click="addRowsSchedule"
                      v-shortkey="['ctrl', 'g']"
                      @shortkey="addRowsSchedule"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.description">
                    {{ item.description }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.description"
                        @blur="item.description = item.description.toUpperCase()"
                        label="Descripción"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click="deleteItemAgenda(item.id)">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                  <span>No se encontró información</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col cols="12" sm="12" class="py-0">
              <v-data-table
                dense
                :headers="cAcuerdos"
                :items="acuerdos"
                :items-per-page="-1"
                sort-by="calories"
                class="border"
                hide-default-footer
              >
                <template v-slot:top>
                  <div class="primary w-100 d-flex justify-space-between px-2 white--text body-1" style="height: 40px;">
                    <div class="d-flex align-center py-1">
                      <u>A</u>CUERDOS
                      <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                    </div>
                    <v-btn
                      color="white"
                      fab
                      icon
                      small
                      @click="addRowsAgreement"
                      v-shortkey="['ctrl', 'a']"
                      @shortkey="addRowsAgreement"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:[`item.engagement`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.engagement">
                    {{ item.engagement }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.engagement"
                        @blur="item.engagement = item.engagement.toUpperCase()"
                        label="Compromiso"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.responsible`]="{ item }">
                  <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div>
                      <ul class="common-list my-2">
                        <li :key="i" v-for="(e, i) in item.responsible">
                          <b v-if="e.isResponsibleItem">{{ e.name }}</b>
                          <span v-else>{{ e.name }}</span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <v-btn icon color="primary" @click="openDialogResponsibles(item.id)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.area`]="{ item }">
                  <div style="display: flex;align-items: center;">
                    <div>
                      <ul class="common-list my-2">
                        <li :key="i" v-for="(e, i) in item.responsible">
                          {{ e.subarea ? e.subarea : 'OTROS' }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.tipo`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.tipo">
                    {{ item.tipo == 'A' ? 'ACUERDO' : 'COMPROMISO' }}
                    <template v-slot:input>
                      <v-select
                        v-model="item.tipo"
                        :items="itemsTipoAC"
                        no-data-text="No se encontró información"
                        item-text="text"
                        item-value="value"
                        label="Estado"
                        single-line
                      >
                      </v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.date">
                    {{
                      item.date
                        .split('-')
                        .reverse()
                        .join('-')
                    }}
                    <template v-slot:input>
                      <v-date-picker
                        ref="picker"
                        v-model="item.date"
                        locale="es-es"
                        :first-day-of-week="1"
                        landscape
                      ></v-date-picker>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <!--                     <v-chip color="blue" dark> Por Vencer</v-chip> -->
                  <v-edit-dialog :return-value.sync="item.status">
                    <v-chip :color="`${item.status.color} darken-2`" dark>{{ item.status.text }}</v-chip>
                    <template v-slot:input>
                      <v-select
                        v-model="item.status"
                        :items="itemsStatusResponsible"
                        no-data-text="No se encontró información"
                        item-text="text"
                        item-value="value"
                        label="Tipo"
                        return-object
                        single-line
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click="deleteItemAcuerdos(item.id)">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                  <span>No se encontró información</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="error" @click="$emit('closeActasEdit')">Cerrar</v-btn>
          <v-btn small v-auth-acl="'gh-act-act_reu-insert'" color="primary" submit @click="actualizar()"
            >Actualizar</v-btn
          >
        </v-card-actions>
        <v-dialog v-model="dialog" scrollable max-width="500px">
          <v-card>
            <v-card-title>Seleccione Responsable</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-0 pt-2" style="height: 500px">
              <v-checkbox
                dense
                v-model="selectedResponsibleAll"
                label="TODOS"
                @change="changeSelectedResponsibleAll"
              ></v-checkbox>
              <v-checkbox
                dense
                v-model="selectedResponsible"
                :label="e.name"
                :value="e"
                :key="i"
                v-for="(e, i) in itemsResponsible"
                @change="checkResponsable"
              >
                <template v-slot:append>
                  <v-row>
                    <v-col cols="12" class="px-3 py-0" style="margin-top:-10px" v-if="e.visibleCheck"> RF </v-col>
                    <v-col cols="12" class="px-3 py-0" v-if="e.visibleCheck">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-simple-checkbox
                            v-if="e.idsubarea !== ''"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop
                            v-model="e.isResponsibleItem"
                            @input="changeResponsableAcuerdo(e)"
                            :ripple="false"
                          ></v-simple-checkbox>
                          <v-simple-checkbox
                            v-else
                            v-bind="attrs"
                            v-on="on"
                            @click.stop
                            :disabled="true"
                            :ripple="false"
                          ></v-simple-checkbox>
                        </template>
                        <span v-if="e.idsubarea !== ''">Seleccione como responsable</span>
                        <span v-else>No puede seleccionar como responsable</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </template>
              </v-checkbox>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="error" text @click="closeDialog">Cerrar</v-btn>
              <v-btn color="primary" text @click="confirmDialog">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import MatrizActaService from '../services/MatrizActaService';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
/* import { notificateActas } from '@/services/notificationService'; */
import { decryptAES } from '@/components4x/utils/utils4x';

export default {
  name: 'ActasReunionFormulario',
  components: {},
  props: {
    idreferencia: {
      type: String,
      default: ''
    },
    idactaaux: {
      type: String,
      default: ''
    },
    referencia: {
      type: String,
      default: ''
    },
    dialogActa: {
      type: Boolean,
      default: false
    },
    objectActa: {
      type: Array,
      default: () => [{}]
    }
  },
  data: () => ({
    confirmResponsible: false,
    showLoading: false,
    actaService: null,
    dialog: false,
    selectedResponsibleAll: false,
    actadescripcion: '',
    tiporeferencia: '',
    titulo: '',
    subtitulo: '',
    required: (v) => v.length !== 0 || 'El campo no debe estar vacío',
    escribano: {
      area: null,
      code: null,
      dni: null,
      email: null,
      id: null,
      idsubarea: null,
      isResponsibleItem: false,
      name: null,
      subarea: null,
      visibleCheck: false
    },
    cParticipantes: [
      {
        text: 'Nombres',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Dni',
        align: 'start',
        sortable: false,
        value: 'dni'
      },
      { text: 'Correo', value: 'email' },
      { text: 'Lider', value: 'leader' },
      { text: 'Estado', value: 'status' },
      { text: 'Acciones', value: 'actions' }
    ],
    participantes: [],
    cAgenda: [
      { text: 'N°', align: 'start', value: 'index', width: '100' },
      { text: 'Descripción', value: 'description' },
      { text: 'Acciones', value: 'actions' }
    ],
    agenda: [],
    cAcuerdos: [
      { text: 'N°', align: 'start', value: 'index', width: '100' },
      { text: 'Compromisos', value: 'engagement' },
      { text: 'Responsable', value: 'responsible' },
      { text: 'Área', value: 'area' },
      { text: 'Tipo', value: 'tipo' },
      { text: 'Fecha', value: 'date', width: '130' },
      { text: 'Estado', value: 'status' },
      { text: 'Acciones', value: 'actions', width: '130', align: 'center' }
    ],
    acuerdos: [],
    selectStatus: { value: 'S', text: 'Si' },
    itemsStatus: [
      { value: 'S', text: 'Si' },
      { value: 'I', text: 'Invitado' },
      { value: 'N', text: 'No' }
    ],
    itemsTipoAC: [
      { value: 'A', text: 'ACUERDO' },
      { value: 'C', text: 'COMPROMISO' }
    ],
    selectStatusResponsible: { value: 'PV', color: 'blue', text: 'Por Vencer' },
    itemsStatusResponsible: [
      { value: 'RE', color: 'green', text: 'Realizado' },
      { value: 'PV', color: 'blue', text: 'Por Vencer' },
      { value: 'NR', color: 'red', text: 'No Realizado' },
      { value: 'CA', color: 'back', text: 'Cancelado' }
    ],
    itemsResponsible: [],
    itemsEscribano: [],
    itemsParticipantes: [],
    selectedResponsible: [],
    idResponsible: '',
    participacion: 0,
    search: ''
  }),
  computed: {},
  watch: {
    objectActa(val) {
      this.limpiar();
      if (val.length > 0) {
        this.titulo = val[0].titulo;
        this.subtitulo = val[0].subtitulo;
        this.participacion = val[0].porcparticipantes;
        val[0].agendas.forEach((el) => {
          this.agenda.push({
            index: el.item,
            description: el.descripcion
          });
        });
        val[0].invitados.forEach((el) => {
          this.participantes.push({
            area: el.area,
            code: el.idresponsable,
            dni: el.idtrabajador,
            email: el.email,
            id: uuidv4(),
            idsubarea: el.idarea,
            leader: el.lider == 1 ? true : false,
            name: el.nombresall,
            new: false,
            object: {
              area: el.area,
              code: el.idresponsable,
              dni: el.idtrabajador,
              email: el.email,
              id: uuidv4(),
              idsubarea: el.idarea,
              leader: el.lider == 1 ? true : false,
              name: el.nombresall,
              new: false,
              object: {},
              status: el.estado,
              subarea: el.subarea
            },
            status: el.estado,
            subarea: el.subarea
          });
        });
        this.loadItemsResponsible();
        this.itemsEscribano.forEach((el) => {
          if (el.code == val[0].idescribano) {
            this.escribano = {
              area: el.area,
              code: el.code,
              dni: el.dni,
              email: el.email,
              id: el.id,
              idsubarea: el.idsubarea,
              isResponsibleItem: false,
              name: el.name,
              text: el.name,
              value: el.dni,
              subarea: el.subarea,
              visibleCheck: false
            };
          }
        });
        val[0].compromisos.forEach((el, index) => {
          const responsablecomp = [];
          el.responsables.forEach((els) => {
            this.itemsResponsible.forEach((elx) => {
              if (elx.code == els.idresponsable) {
                responsablecomp.push({
                  area: elx.area,
                  code: elx.code,
                  dni: elx.dni,
                  email: elx.email,
                  id: elx.id,
                  idsubarea: elx.idsubarea,
                  isResponsibleItem: els.isresponsable == 1 ? true : false,
                  name: elx.name,
                  subarea: elx.subarea,
                  visibleCheck: true
                });
              }
            });
          });
          index = index + 1;
          this.acuerdos.push({
            id: uuidv4(),
            index: index.toString().padStart(3, '0'),
            engagement: el.nombrecompromiso,
            responsible: responsablecomp,
            tipo: el.tipo,
            date: el.fechafin,
            status:
              el.estatus == 'PV'
                ? { value: 'PV', color: 'blue', text: 'Por Vencer' }
                : el.estatus == 'RE'
                ? { value: 'RE', color: 'green', text: 'Realizado' }
                : el.estatus == 'NR'
                ? { value: 'NR', color: 'red', text: 'No Realizado' }
                : { value: 'CA', color: 'back', text: 'Cancelado' }
          });
        });
      }
    }
  },
  methods: {
    limpiar() {
      this.titulo = '';
      this.subtitulo = '';
      this.participacion = 0;
      this.agenda = [];
      this.participantes = [];
      this.acuerdos = [];
      this.escribano = [];
    },
    async initialize() {
      this.showLoading = true;
      const res = await this.actaService.get().execResource('responsables', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });

      if (res.length > 0) {
        res.forEach((el) => {
          this.itemsParticipantes.push({
            id: uuidv4(),
            code: el.idresponsable,
            dni: el.idtrabajador,
            name: el.nombresall,
            email: el.email,
            idsubarea: el.idsubarea,
            subarea: el.subarea,
            area: el.area, // En realidad es la subarea
            status: 'S',
            leader: false,
            new: false,
            object: {}
          });
        });
      } else {
        this.itemsParticipantes = [];
      }

      this.showLoading = false;
    },
    async actualizar() {
      if (this.titulo.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un título');
        return;
      }

      if (this.participantes.length <= 0) {
        await this.alertDialog('warning', 'No hay invitados/participantes');
        return;
      }

      if (this.escribano.code === null || this.escribano.code === '') {
        await this.alertDialog('warning', 'Debe registrar un escribano válido');
        return;
      }

      if (this.agenda.length <= 0) {
        await this.alertDialog('warning', 'La agenta está vacía');
        return;
      }

      if (this.acuerdos.length <= 0) {
        await this.alertDialog('warning', 'Los acuerdos están vacíos');
        return;
      }

      let auxDni = false;
      this.participantes.forEach((el) => {
        if (el.dni == '' || el.nombre == '') {
          auxDni = true;
        }
      });
      if (auxDni) {
        this.alertDialog('warning', 'Falta dni/nombres de algunos participantes');
        return;
      }
      let auxResponsible = false;
      let auxEngagement = false;
      this.acuerdos.forEach((el) => {
        if (el.responsible.length <= 0) {
          auxResponsible = true;
        }
        if (el.engagement == '') {
          auxEngagement = true;
        }
      });

      if (auxResponsible) {
        this.alertDialog('warning', 'Faltan responsables en algunos compromisos/acuerdos');
        return;
      }
      if (auxEngagement) {
        this.alertDialog('warning', 'Faltan nombres en algunos compromisos/acuerdos');
        return;
      }
      const leader = this.participantes.filter((el) => el.leader);
      const schedule = [];

      if (leader.length <= 0) {
        await this.alertDialog('warning', 'Debe elegir un líder');
        return;
      }
      this.agenda.forEach((el) => {
        if (el.description !== '') schedule.push({ item: el.index, descripcion: el.description });
      });

      const participants = [];

      this.participantes.forEach((el) => {
        console.log(el, 'participantes');
        participants.push({
          idresponsable: el.code,
          idtrabajador: el.dni,
          nombres: el.name.toUpperCase(),
          email: el.email,
          idarea: el.idsubarea,
          subarea: el.subarea,
          area: el.area,
          lider: el.leader ? 1 : 0,
          estado: el.status
        });
      });

      const agreements = [];

      this.acuerdos.forEach((el) => {
        const responsibles = [];
        console.log(el, 'acuerdos');
        el.responsible.forEach((elem) => {
          responsibles.push({
            idtrabajador: elem.dni,
            responsable: elem.name,
            area: elem.idsubarea,
            isresponsable: elem.isResponsibleItem ? 1 : 0,
            email: elem.email
          });
        });
        agreements.push({
          item: el.index,
          compromiso: el.engagement,
          responsables: responsibles,
          tipo: el.tipo,
          estado: el.status.value,
          fechafin: el.date,
          observaciones: ''
        });
      });

      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea guardar los cambios?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const res = await this.actaService.put().execResource('editar', {
          idacta: this.idactaaux,
          idempresa: decryptAES(localStorage.getItem('emp')),
          descripcion: '',
          idreferencia: this.idreferencia,
          tiporeferencia: this.tiporeferencia,
          titulo: this.titulo,
          subtitulo: this.subtitulo,
          idlider: leader[0].code,
          idescribano: this.escribano.code,
          porparticipantes: this.participacion,
          dagendas: schedule,
          dparticipantes: participants,
          dacuerdos: agreements,
          obs: ''
        });

        this.showLoading = false;

        if (res.status) {
          /*  for (const participant of participants) {
            if (participant.idresponsable !== '') await notificateActas(participant.idresponsable, res.codigo);
          } */
          await this.alertDialog('success', res.data);
          this.$emit('closeActasEdit');
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async alertDialogYesNo(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Guardar`,
        confirmButtonText: `Guardar y enviar`,
        reverseButtons: true
      });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    changeSelectedResponsibleAll() {
      this.selectedResponsible = [];
      if (this.selectedResponsibleAll) {
        this.itemsResponsible.map((el) => {
          el.isResponsibleItem = false;
          el.visibleCheck = true;
          return el;
        });

        this.itemsResponsible.forEach((el) => {
          this.selectedResponsible.push(el);
        });
      } else {
        this.itemsResponsible.map((el) => {
          el.isResponsibleItem = false;
          el.visibleCheck = false;
          return el;
        });
      }
    },
    changeLeader(id, codigo) {
      this.participantes.map((el) => (el.leader = el.id === id && el.codigo === codigo));
    },
    addRows() {
      this.participantes.push({
        id: uuidv4(),
        dni: '',
        code: '',
        name: '',
        email: '',
        leader: false,
        area: '',
        idsubarea: '',
        subarea: '',
        status: 'S',
        new: false,
        object: {}
      });
      this.updateParticipation();
    },
    addRowsSchedule() {
      const index = this.agenda.length <= 0 ? 1 : this.agenda.length + 1;
      this.agenda.push({ id: uuidv4(), index: index.toString().padStart(3, '0'), description: '' });
    },
    addRowsAgreement() {
      const index = this.acuerdos.length <= 0 ? 1 : this.acuerdos.length + 1;
      this.acuerdos.push({
        id: uuidv4(),
        index: index.toString().padStart(3, '0'),
        engagement: '',
        responsible: [],
        tipo: 'A',
        date: new Date().toISOString().substr(0, 10),
        status: { value: 'PV', color: 'blue', text: 'POR VENCER' }
      });
    },
    // CELL EDIT
    loadItemsResponsible() {
      this.itemsResponsible = [];
      this.itemsEscribano = [];
      this.participantes.forEach((el) => {
        if (el.dni !== '') {
          this.itemsResponsible.push({
            id: el.id,
            code: el.code,
            dni: el.dni,
            name: el.name,
            email: el.email,
            area: el.area,
            idsubarea: el.idsubarea,
            subarea: el.subarea,
            visibleCheck: false,
            isResponsibleItem: false
          });
          if (el.idsubarea !== '') {
            this.itemsEscribano.push({
              id: el.id,
              code: el.code,
              dni: el.dni,
              name: el.name,
              email: el.email,
              area: el.area,
              idsubarea: el.idsubarea,
              subarea: el.subarea,
              visibleCheck: false,
              isResponsibleItem: false
            });
          }
        }
      });
    },
    clearRowInvitados(id) {
      const i = this.participantes.findIndex((el) => el.id === id);
      this.participantes.splice(i, 1);
    },
    saveName(id, object, inew) {
      if (inew) return;

      if (object === undefined) {
        this.clearRowInvitados(id);
        return;
      }

      const dni = object.dni;

      if (dni === '' || dni === undefined) {
        this.clearRowInvitados(id);
        return;
      }

      const res = this.itemsParticipantes.filter((el) => el.dni === dni);
      this.participantes.map((el) => {
        if (el.id === id) {
          el.dni = res[0].dni;
          el.code = res[0].code;
          el.name = res[0].name;
          el.email = res[0].email;
          el.idsubarea = res[0].idsubarea;
          el.subarea = res[0].subarea;
          el.area = res[0].area;
          el.status = 'S';
        }
        return el;
      });
    },
    loadEscribanos() {
      this.loadItemsResponsible();
    },
    openDialogResponsibles(id) {
      this.loadItemsResponsible();
      this.selectedResponsible = [];
      this.idResponsible = id;
      this.dialog = true;
      const res = this.acuerdos.filter((el) => el.id === id);

      if (res[0].responsible.length <= 0) return;

      for (const arr of res[0].responsible) {
        this.selectedResponsible.push(Object.assign({}, arr));
      }
      if (this.selectedResponsible.length > 0) {
        this.selectedResponsible.forEach((el) => {
          this.itemsResponsible.forEach((ele) => {
            if (el.code == ele.code) {
              ele.isResponsibleItem = el.isResponsibleItem;
              ele.visibleCheck = el.visibleCheck;
            }
          });
        });
      }
    },
    closeDialog() {
      this.selectedResponsible = [];
      this.idResponsible = '';
      this.selectedResponsibleAll = false;
      this.dialog = false;
    },
    confirmDialog() {
      let res = [];
      let auxPermiso = false;
      this.selectedResponsible.forEach((el) => {
        if (el.isResponsibleItem) {
          auxPermiso = true;
        }
      });
      if (auxPermiso) {
        for (const responsible of this.selectedResponsible) {
          res.push(Object.assign({}, responsible));
        }
        this.acuerdos.map((el) => (el.responsible = el.id === this.idResponsible ? res : el.responsible));
        this.selectedResponsible = [];
        this.selectedResponsibleAll = false;
        this.dialog = false;
      } else {
        this.alertDialog('warning', 'Seleccione un responsable de acuerdo/compromiso');
      }
    },
    deleteItemParticipantes(id) {
      const i = this.participantes.findIndex((el) => el.id === id);
      this.participantes.splice(i, 1);
      this.updateParticipation();
    },
    deleteItemAcuerdos(id) {
      const i = this.acuerdos.findIndex((el) => el.id === id);
      this.acuerdos.splice(i, 1);
      this.acuerdos.map((el, index) => (el.index = (index + 1).toString().padStart(3, '0')));
    },
    deleteItemAgenda(id) {
      const i = this.agenda.findIndex((el) => el.id === id);
      this.agenda.splice(i, 1);
      this.agenda.map((el, index) => (el.index = (index + 1).toString().padStart(3, '0')));
    },
    updateParticipation() {
      const total = this.participantes.reduce(
        (sum, el) => sum + (el.status.toUpperCase() === 'S' || el.status.toUpperCase() === 'N' ? 1 : 0),
        0
      );
      const asistentes = this.participantes.reduce((sum, el) => sum + (el.status.toUpperCase() === 'S' ? 1 : 0), 0);
      const res = Math.round((asistentes * 100) / total);
      this.participacion = isNaN(res) ? 0 : res;
    },
    changeNewEditText(id) {
      this.participantes.map((el) => (el.new = el.id === id ? true : el.new));
    },
    saveEmail(id) {
      this.participantes.map(
        (el) =>
          (el.email =
            el.id === id ? (el.email.indexOf('@') !== -1 ? el.email : `${el.email}@agrovisioncorp.com`) : el.email)
      );
    },
    async loadEvent(id) {
      this.showLoading = true;
      const res = await this.actaService.get().execResource('eventos', {
        idevento: id
      });
      this.showLoading = false;

      if (!Array.isArray(res)) {
        this.actadescripcion = `Evento: "${res.asunto.toUpperCase()}" ( ${res.start.split('.')[0]} ~ ${
          res.end.split('.')[0]
        } )`;

        this.tiporeferencia = res.tipo;

        res.invitados.forEach((e) => {
          const res = this.itemsParticipantes.filter((el) => el.dni === e.idtrabajador);
          this.participantes.push({
            id: uuidv4(),
            dni: res[0].dni,
            code: res[0].code,
            name: res[0].name,
            email: res[0].email,
            area: res[0].area,
            idsubarea: res[0].idsubarea,
            subarea: res[0].subarea,
            status: 'S',
            leader: false,
            new: false,
            object: {}
          });
        });

        this.updateParticipation();
      } else {
        this.actadescripcion = '';
        this.participantes = [];
        await this.alertDialog('error', 'Evento no existe');
        await this.$router.push({ path: '/calendario-comites/evento' });
      }
    },
    async loadComite(id) {
      this.showLoading = true;
      const res = await this.actaService.get().execResource('comites', {
        idcomite: id
      });
      this.showLoading = false;
      if (!Array.isArray(res)) {
        this.actadescripcion = `Comité: "${res.titulo.toUpperCase()}" ( ${res.start.split('.')[0]} ~ ${
          res.end.split('.')[0]
        } )`;

        this.tiporeferencia = res.tipo;

        if (res.invitados.length > 0) {
          res.invitados.forEach((e) => {
            const res = this.itemsParticipantes.filter((el) => el.dni === e.idtrabajador);
            this.participantes.push({
              id: uuidv4(),
              dni: res[0].dni,
              code: res[0].code,
              name: res[0].name,
              email: res[0].email,
              area: res[0].area,
              idsubarea: res[0].idsubarea,
              subarea: res[0].subarea,
              status: 'S',
              leader: false,
              new: false,
              object: {}
            });
          });
        }

        if (res.temasatratar.length > 0) {
          res.temasatratar.forEach((e) => {
            this.agenda.push({ id: uuidv4(), index: e.index, description: e.tema });
          });
        }

        this.updateParticipation();
      } else {
        this.actadescripcion = '';
        this.participantes = [];
        await this.alertDialog('error', 'Comite no existe');
        await this.$router.push({ path: '/calendario-comites/comite' });
      }
    },
    async loadTarea(id) {
      this.showLoading = true;
      let idTarea = id.split('-');
      const res = await this.actaService.get().execResource('tareas', {
        idtarea: idTarea[1],
        item: idTarea[2],
        idfichatecnicapy: idTarea[0]
      });
      this.showLoading = false;
      if (res.length > 0) {
        this.actadescripcion = `Tarea: "${res[0].titulo.toUpperCase()} - ${res[0].descripcion.toUpperCase()}" ( ${
          res[0].subtareas.fecha
        } ~ ${res[0].subtareas.fechafin} )`;

        this.tiporeferencia = res[0].tipo;

        if (res[0].jsonresponsables.length > 0) {
          res[0].jsonresponsables.forEach((e) => {
            const res = this.itemsParticipantes.filter((el) => el.dni === e.dni);
            this.participantes.push({
              id: uuidv4(),
              dni: res[0].dni,
              code: res[0].code,
              name: res[0].name,
              email: res[0].email,
              area: res[0].area,
              idsubarea: res[0].idsubarea,
              subarea: res[0].subarea,
              status: 'S',
              leader: false,
              new: false,
              object: {}
            });
          });
        }
        if (res[0].jsonsoporte.length > 0) {
          res[0].jsonsoporte.forEach((e) => {
            const res = this.itemsParticipantes.filter((el) => el.dni === e.dni);
            this.participantes.push({
              id: uuidv4(),
              dni: res[0].dni,
              code: res[0].code,
              name: res[0].name,
              email: res[0].email,
              area: res[0].area,
              idsubarea: res[0].idsubarea,
              subarea: res[0].subarea,
              status: 'S',
              leader: false,
              new: false,
              object: {}
            });
          });
        }
        this.updateParticipation();
      } else {
        this.actadescripcion = '';
        this.participantes = [];
        await this.alertDialog('error', 'Tarea no existe');
        await this.$router.push({ path: '/calendario-comites/tarea' });
      }
    },
    changeResponsableAcuerdo({ id }) {
      if (this.selectedResponsible.length > 0) {
        this.selectedResponsible.map((el) => {
          el.isResponsibleItem = el.id === id;
          return el;
        });
        this.itemsResponsible.map((el) => {
          el.isResponsibleItem = el.id === id;
          return el;
        });
      }
    },
    checkResponsable(event) {
      if (this.itemsResponsible.length > 0) {
        if (event.length > 0) {
          this.itemsResponsible.forEach((el) => {
            event.forEach((e) => {
              if (e.code != el.code) {
                el.visibleCheck = false;
                el.isResponsibleItem = false;
                e.visibleCheck = false;
                e.isResponsibleItem = false;
              }
            });
          });
          event.forEach((el) => {
            this.itemsResponsible.forEach((e) => {
              if (e.code == el.code) {
                e.visibleCheck = true;
                e.isResponsibleItem = false;
                el.visibleCheck = true;
                el.isResponsibleItem = false;
              }
            });
          });
        }
      }
      if (event.length == 0) {
        this.itemsResponsible.map((el) => {
          el.visibleCheck = false;
          el.isResponsibleItem = false;
          return el;
        });
      }
    }
  },
  async mounted() {},
  async created() {
    this.actaService = this.$httpService(new MatrizActaService(), this);
    await this.initialize();
    if (this.idreferencia === '' || this.referencia === '') {
      return;
    }

    switch (this.referencia) {
      case 'E':
        await this.loadEvent(this.idreferencia);
        break;
      case 'C':
        await this.loadComite(this.idreferencia);
        break;
      case 'T':
        await this.loadTarea(this.idreferencia);
        break;
    }
  }
};
</script>
